<template>
  <div id="index">
    <el-container style="height: 100%; overflow-y: hidden;">
      <el-header  style="padding:0; height:72px; text-align: right; font-size: 12px">
        <headers @getrusult="getdata"></headers>
      </el-header>
      <el-container>
        <div class="aside">
          <el-aside width="240px">
            <el-menu
              :router="true"
              :default-active="$route.path"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              :collapse="isCollapse"
            >
              <el-submenu index="1">
                <template slot="title">
                  <i class="el-icon-house"></i>
                  <span slot="title">首页</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="/p/home">统计</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="/p/cashOutAccount">提款账号</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="/p/balanceCashOut">余额提现</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="/p/cashOutList">提现列表</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="2">
                <template slot="title">
                  <i class="el-icon-user"></i>
                  <span slot="title">合伙人</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="/p/partner">合伙人管理</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="/p/promoter">推广员</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="/p/partnerCashOut">提现管理</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="3">
                <template slot="title">
                  <i class="el-icon-office-building"></i>
                  <span slot="title">商家管理</span>
                </template>
                <el-menu-item-group>
                  <el-submenu index="3-4">
                    <template slot="title" index="/p/businessManage">商家管理</template>
                    <el-menu-item index="/p/businessManage?type=0">已提交</el-menu-item>
                    <el-menu-item index="/p/businessManage?type=1">待签约</el-menu-item>
                    <el-menu-item index="/p/businessManage?type=2">已审核</el-menu-item>
                    <el-menu-item index="/p/businessManage?type=3">已拒绝</el-menu-item>
                  </el-submenu>
                  <!-- <el-menu-item index="/p/businessManage">商家管理</el-menu-item>
                  <el-menu-item index="2-4-1">选项1</el-menu-item>
                  <el-menu-item index="2-4-2">选项2</el-menu-item>
                  <el-menu-item index="2-4-3">选项3</el-menu-item> -->
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="/p/businessCashOut">商家提现</el-menu-item>
                </el-menu-item-group>
                <!-- <el-menu-item-group>
                  <el-menu-item index="/p/butlerDetail">入账明细</el-menu-item>
                </el-menu-item-group> -->
              </el-submenu>
              <el-menu-item index="/p/butlerDetail">
                <template slot="title">
                  <i class="el-icon-s-order"></i>
                  <span slot="title">入账记录</span>
                </template>
              </el-menu-item>
              <el-submenu index="4">
                <template slot="title">
                  <i class="el-icon-document"></i>
                  <span slot="title">订单对账</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="/p/paidOrders">已支付订单</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="/p/verifiedRecords">已核销记录</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="/p/recordCanceled">已取消记录</el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item index="/p/refundRecord">已退款记录</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <a href="https://life.douyin.com" target="_blank">
                <el-submenu index="5" class="platform_avatar">
                  <template slot="title">
                    <i class="el-icon-sold-out"></i>
                    <span slot="title">商品管理</span>
                  </template>
                </el-submenu>
              </a>
              <!-- <span @click="toLinks('AI矩阵')">
                <el-submenu index="6" class="platform_avatar">
                  <template slot="title">
                    <i class="el-icon-receiving"></i>
                    <span slot="title">AI矩阵</span>
                  </template>
                </el-submenu>
              </span>
              <span @click="toLinks('AI实景直播')">
                <el-submenu index="7" class="platform_avatar">
                  <template slot="title">
                    <i class="el-icon-data-analysis"></i>
                    <span slot="title">AI实景直播</span>
                  </template>
                </el-submenu>
              </span> -->
              <a href="https://wmp.fuioupay.com" target="_blank">
                <el-submenu index="8" class="platform_avatar">
                  <template slot="title">
                    <i class="el-icon-collection"></i>
                    <span slot="title">富掌柜</span>
                  </template>
                </el-submenu>
              </a>
              <!-- <a href="https://tuoke.maronettee.com" target="_blank">
                <el-submenu index="9" class="platform_avatar">
                  <template slot="title">
                    <i class="el-icon-suitcase"></i>
                    <span slot="title">聚合推广</span>
                  </template>
                </el-submenu>
              </a>
              <a href="https://ketui.yunmaitui.com" target="_blank">
                <el-submenu index="10" class="platform_avatar">
                  <template slot="title">
                    <i class="el-icon-box"></i>
                    <span slot="title">客推店</span>
                  </template>
                </el-submenu>
              </a> -->
              <el-submenu index="11">
                <template slot="title">
                  <i class="el-icon-setting"></i>
                  <span slot="title">设置</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="/p/withdrawalSettings">提现设置</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <a :href="`${protocol}//${LocalURL}/datascreen?uniacid=${user.uniacid}`" target="_blank">
                <el-submenu index="12" class="platform_avatar">
                  <template slot="title">
                    <i class="el-icon-data-analysis"></i>
                    <span slot="title">数据大屏</span>
                  </template>
                </el-submenu>
              </a>
            </el-menu>
          </el-aside>
        </div>
        <div class="main">
          <el-main>
            <router-view name="table" />
          </el-main>
        </div>
      </el-container>
    </el-container>

    <!-- <headers @getrusult="getdata"></headers>
    <div class="box-center">
      <aside class="box-center-left">
        <el-menu
          :router="true"
          :default-active="$route.path"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          :collapse="isCollapse"
        >
          <el-submenu index="0">
            <template slot="title">
              <i class="el-icon-house"></i>
              <span slot="title">首页</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/p/home">统计</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/p/home">提款账号</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/p/home">余额提现</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/p/home">提现列表</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-user"></i>
              <span slot="title">合伙人管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/p/about">合伙人管理</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/p/about">推广员管理</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/p/about">提现管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-office-building"></i>
              <span slot="title">商家管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/p/goods">商家管理</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/p/goods">商家提现</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span slot="title">订单管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/order/tradeDetail">已支付订单</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/order/verification">已核销记录</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/order/refund">已取消记录</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/order/refund">已退款记录</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span slot="title">设置</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/finance/dailyBenifits">提现设置</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </aside>
      <main class="main">
        
        <div class="header">
            <i @click="bindCollapse" class="el-icon-s-fold header_i"></i>
            <div class="user-wrapper">
              <img  src="../assets/login.png" alt="">
              <div class="khd-hover" @click="quit">
                <i class="el-icon-switch-button"></i>退出登录
              </div>  
            </div>
        </div>

        <router-view name="table" />
      </main>
    </div> -->

    <!-- 消息 -->
    <el-drawer
      title="消息中心"
      :visible.sync="drawer"
      :with-header="false"
      :wrapperClosable="false"
      :close-on-press-escape="false">
      <div class="drawer">
        <div class="drawertit">
          <div class="back">
            <i v-if="result == 1" @click="result = 0" class="el-icon-arrow-left"></i>
            消息中心
          </div>
          <div class="close">
            <i @click="drawer = false" class="el-icon-close"></i>
          </div>
        </div>
        <div class="drawer-content" v-if="result == 0">
          <div class="drawer-content-life">
            <div class="scroll">
              <div class="item"  @click="binddrawerac(1)"
            :class="drawerac == 1 ? 'active' : ''">
                全部
              </div>
              <div class="item"  @click="binddrawerac(2)"
            :class="drawerac == 2 ? 'active' : ''">
            平台服务
              </div>
              <div class="item"  @click="binddrawerac(3)"
            :class="drawerac == 3 ? 'active' : ''">
            门店管理
              </div>
              <div class="item"  @click="binddrawerac(4)"
            :class="drawerac == 4 ? 'active' : ''">
            商品
              </div>
              <div class="item"  @click="binddrawerac(5)"
            :class="drawerac == 5 ? 'active' : ''">
            财务结算
              </div>
            </div>
          </div>
          <div class="drawer-content-con" >
            <div class="item">
              <div class="item-top">
                <img src="https://p3-life-hermes-sign.byteimg.com/tos-cn-i-hf2m9xxmck/38ea48468a54876b522477ca7bcf70bd.png~tplv-hf2m9xxmck-image.image?x-expires=1672444800&x-signature=le7A0%2Bmf6Qqh6STFQG0DGy4QLFs%3D" alt="">
                <div class="text">巨量本地推茶饮案例 @茶百道</div>
              </div>
              <div class="oo-mask">
                茶百道年度第三次大场抖音直播，首次尝试全以巨量本地推引流，实现巨量本地推整体ROI高于均值40%，GMV突破8300W！作为入驻抖音生活服务较早的茶饮品牌，屡创赛道GMV新峰值，茶饮增长点在哪里，茶百道给出了自己的答案。
                <div class="item-btm">
                  <div class="pre">营销推广  昨天 16:01</div>
                  <el-button @click="xq" plain>详情</el-button>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="item-top">
                <img src="https://p3-life-hermes-sign.byteimg.com/tos-cn-i-hf2m9xxmck/38ea48468a54876b522477ca7bcf70bd.png~tplv-hf2m9xxmck-image.image?x-expires=1672444800&x-signature=le7A0%2Bmf6Qqh6STFQG0DGy4QLFs%3D" alt="">
                <div class="text">软件服务费返还账单确认提醒</div>
              </div>
              <div class="oo-mask">
                "2022年10月软件服务费返还账单"已出账，请于10日内完成账单确认处理，超过10日未处理平台将自动确认该账单。点击查看详情
                <div class="item-btm">
                  <div class="pre">营销推广  昨天 16:01</div>
                  <el-button  @click="xq" plain>详情</el-button>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="item-top">
                <img src="https://p3-life-hermes-sign.byteimg.com/tos-cn-i-hf2m9xxmck/38ea48468a54876b522477ca7bcf70bd.png~tplv-hf2m9xxmck-image.image?x-expires=1672444800&x-signature=le7A0%2Bmf6Qqh6STFQG0DGy4QLFs%3D" alt="">
                <div class="text">叮～诚邀你参与有奖调研</div>
              </div>
              <div class="oo-mask">
                亲爱的商家，你好！为了解你在抖音平台上的经营体验，诚邀你参与调查，我们将邀请符合条件的伙伴参与有奖访谈。你的声音，非常重要。问卷填写截止时间：2022年11月20日。期待与你交流！【本活动与Apple Inc.无关】
                <div class="item-btm">
                  <div class="pre">营销推广  昨天 16:01</div>
                  <el-button @click="xq" plain>详情</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="drawer-content" v-else>
          亲爱的商家，你好！为了解你在抖音平台上的经营体验，诚邀你参与调查，我们将邀请符合条件的伙伴参与有奖访谈。你的声音，非常重要。问卷填写截止时间：2022年11月20日。期待与你交流！【本活动与Apple Inc.无关】
          </div>
      </div>
    </el-drawer>

    <!-- 二维码展示dialog -->
    <el-dialog :title="title" :visible.sync="dialogFormVisible" width="30%" @close="dialogFormClose">
      <el-image :src="qrCodeSrc">
        <div slot="placeholder" class="image-slot">
          加载中<span class="dot">...</span>
        </div>
      </el-image>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import headers from "../components/header.vue";
import { mapState, mapMutations } from "vuex";

export default({
  name: "index",
  components: {
    headers,
  },
  data() {
    return {
      isCollapse: false,
      drawer:false, // 消息抽屉
      drawerac:1,
      result:0,
      // activeMenu: '',

      title: '',
      dialogFormVisible: false,
      qrCodeSrc: '',
      LocalURL: '',
      protocol: '',
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
    let user = JSON.parse(localStorage.user);
    this.LocalURL = window.location.host
    this.protocol = window.location.protocol
    // console.log('this.user', this.user);
    if (user != undefined) {
      this.$notify({
        title: "欢迎您用户",
        message: "登录成功",
        type: "success",
        duration: 1000,
      });
      if (this.$store.state.user.token == undefined) {
        this.setuser(user);
      }
    } else {
      localStorage.removeItem("user");
      this.$router.replace("/");
    }

    var mobileAgent = new Array(
      "iphone",
      "ipod",
      "ipad",
      "android",
      "mobile",
      "blackberry",
      "webos",
      "incognito",
      "webmate",
      "bada",
      "nokia",
      "lg",
      "ucweb",
      "skyfire"
    );
    var browser = navigator.userAgent.toLowerCase();
    for (var i = 0; i < mobileAgent.length; i++) {
      if (browser.indexOf(mobileAgent[i]) != -1) {
        this.isCollapse = true;
        break;
      } else {
        this.isCollapse = false;
      }
    }
  },
  methods: {
    ...mapMutations(["setuser", "setsetting"]),
    handleOpen(key, keyPath) {
      // console.log('key, keyPath', key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log('key, keyPath22', key, keyPath);
    },
    bindCollapse() {
      this.isCollapse = !this.isCollapse;
    },

    binddrawerac(e) {
      this.drawerac = e;
    },

    getdata(e){
      this.drawer = e
    },
    xq(){
      this.result = 1
    },
    quit() {
      this.$confirm("真的要注销登录吗 ?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.removeItem("user");
          this.$router.replace("/");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 二维码弹窗
    toLinks(key){
      switch (key) {
        case 'AI矩阵':
          this.$api.getQrCode({}).then(res=>{
              if(res.code == 'success'){
                this.qrCodeSrc = res.data.juzhen
              }else{
                this.$message.error(res.msg)
              }
          }).catch(err=>{
            this.$message.error(err.msg)
          });
          this.title = 'AI矩阵'
          this.dialogFormVisible = true
          break;
        case 'AI实景直播':
          this.$api.getQrCode({}).then(res=>{
              if(res.code == 'success'){
                this.qrCodeSrc = res.data.aisjzb
              }else{
                this.$message.error(res.msg)
              }
          }).catch(err=>{
            this.$message.error(err.msg)
          });
          this.title = 'AI实景直播'
          this.dialogFormVisible = true
          break;

        default:
          break;
      }
    },
    // 二维码弹窗model关闭
    dialogFormClose(){
      this.dialogFormVisible = false
    },
  },
});
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #f2f5fa;
}
.el-container {
  height: 100%;
}

a {
  text-decoration: none;
  color: none;
}
body,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}
div {
  font-weight: normal;
}
input {
  border: none;
  outline: none;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}

.el-menu {
  border: none;
}
.aside {
    width: 240px;
    overflow: hidden;
    height: auto;
    background-color: #fff;
 }
.el-aside {
    height: calc(100vh - 72px);
    overflow-x: hidden;
    overflow-y: overlay;
 }
.el-aside::-webkit-scrollbar {
  width: 0;
}
.main {
  height: calc(100vh - 72px);
  width: calc(100vw - 240px);
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 6px;
}
.main2 {
  width: calc(100vw - 80px);
}
.box-center {
  display: flex;
  .box-center-left {
    width: 256px;
    background: #fff;
    height: 100vh;
    overflow: auto;
    a {
      color: #303133;
    }
    .logo {
      cursor: pointer;
      padding: 20px 0;
      background: #002140;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: #fff;
      font-size: 22px;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
  .max_width {
    max-width: 80px;
    min-width: 80px;
    width: 80px;
    overflow: hidden;
  }
  .header {
    width: 100%;
    background: #fff;
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 20px;
    .header_i {
      font-size: 30px;
    }
    i {
      cursor: pointer;
    }
    img {
      cursor: pointer;
      width: 30px;
      height: 30px;
    }
    .user-wrapper {
      padding: 20px 12px;
      position: relative;
    }
    .khd-hover {
      display: none;
      padding: 10px 0;
      position: absolute;
      width: 145px;
      font-size: 14px;
      right: 0;
      bottom: -20px;
      background: #fff;
      border-radius: 5px;
      text-align: left;
      padding-left: 20px;
      cursor: pointer;
    }
    .user-wrapper:hover .khd-hover {
      display: block;
    }
    .khd-hover:hover {
      background: #E6F7FF;
      color: #40A9FF;
    }
  }
}

@media screen and (max-width: 750px) {
  .el-date-range-picker .el-picker-panel__body {
    min-width: 0;
  }
  .el-date-range-picker__content {
    width: 100%;
  }
  .el-date-range-picker {
    width: 100%;
  }
  .el-picker-panel__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  // .main {
  //   width: calc(100vw - 80px);
  //   height: 100vh;
  // }
  .box-center {
    .box-center-left {
      width: 80px;
      .logo {
        flex-direction: column;
        span {
          font-size: 12px;
          font-weight: 100;
          text-align: center;
        }
      }
    }
  }
}

.drawer{
  .drawertit{
    padding: 20px;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    align-content: center;
    justify-content: space-between;
    .back{
      i{
      font-weight: bold;
      color: #000;
      font-size: 20px;
      cursor: pointer;
    }
    }
    .close{
      i{
      font-weight: bold;
      color: #000;
      font-size: 28px;
      cursor: pointer;
    }
    }
   
  }
  .drawer-content{
    padding: 20px;
    font-size: 14px;
    .drawer-content-life{
      .scroll{
        display: flex;
        justify-content: flex-start;
        padding-bottom: 12px;
        white-space: nowrap;
        .item{
          background-color: #f8f9fa;
          border-radius: 4px;
          color: #565960;
          cursor: pointer;
          flex-basis: auto;
          line-height: 20px;
          padding: 6px 12px;
          margin-right: 24px;
        }
        .active{
          background-color: #ebf3ff;
          color: #1966ff;
          font-weight: 500;
        }
      }
    }
    .drawer-content-con{
     margin-top: 16px;
      height: calc(100vh - 186px);
      overflow: auto;
      .item{
        font-size: 14px;
        margin-bottom: 24px;
        .item-top{
          display: flex;
          align-content: center;
          img{
            height: 30px;
            width: 30px;
          }
          .text{
            font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    margin-left: 12px;
    position: relative;
          }
        }
        .oo-mask{
          font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 12px 0;
        }
        .item-btm{
          display: flex;
          align-content: center;
          justify-content: space-between;
          .pre{
            color: #95989d;
    display: inline-block;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 18px;
    text-align: center;
          }
          button{
            padding: 8px 20px;
          }
        }
      }
    }
  }
}

//取消el-submenu的箭头icon
.platform_avatar{
  height: 55px;
  line-height: 55px;
  .imgUserAvatar{
    width: 32px;
    height: 32px;
  }
  ::v-deep(.el-submenu__title) {
    .el-icon-arrow-down {
       display: none;
    }
  }
}
</style>
