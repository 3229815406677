<template>
    <div class="top">
       <el-row>
          <el-col :span="12">
            <div class="left">
              <img :src="user.logo?user.logo:'@/assets/login.png'" alt="">
              <div class="name">{{user.title?user.title:''}}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="rig">
              <!-- <div class="name flex" @click="xiaoxi">
                <i  class="el-icon-bell color-icon"></i>消息
              </div> -->
               <div class="name flex">
                   <img src="@/assets/account.png" alt=""> <span class="gongsi">{{user.loginname?user.loginname:'用户'}}</span>  <i class="el-icon-arrow-down"></i>
  
                   <div class="account">
                     <div class="account-top">
                       <img src="@/assets/account.png" alt="">
                       <div>
                         <div class="gs-name">{{user.loginname?user.loginname:'用户'}}<span>{{user.title}}</span> </div>
                       </div>
                     </div>
                     <!-- <div class="account-box">
                       <div class="item not-allowed">
                         <div>我的账号</div>
                         <i class="el-icon-arrow-down"></i>
                       </div>
                       <div class="space"></div>
                       <div class="item">
                         <div>授权管理</div>
                         <i class="el-icon-arrow-down"></i>
                       </div>
                     </div> -->
                     <div class="quit" @click="quit">退出</div>
                   </div>
              </div>
            </div>
          </el-col>
        </el-row>
    </div>
  </template>
  
  <script>
  import Vue from "vue";
  import { mapState, mapMutations } from "vuex";
  
  export default Vue.extend({
    props: {
        
    },
    data() {
        return {
        };
      },
    computed: {
      ...mapState(["user"]),
      user: {
        get(){                
            return this.$store.state.user            
        },            
        set(val){                
            this.$store.state.user = val            
        }
      }
    },
    mounted(){
      let logStatu =  JSON.parse(localStorage.getItem('user'))
       if(logStatu == null){
        // this.$message.warning("登录已过期")
        localStorage.removeItem('user')
        this.$router.push({name:'login'})
      } else {
        this.getInfo()
      }
    },
    methods:{
      ...mapMutations(["setuser"]),
      // 获取个人信息（提现余额、提现说明）
      getInfo() {
        this.$api.getInfo({}).then(
          res => {
            if(res.code == 'success'){
              this.user = res.data
              localStorage.setItem('user', JSON.stringify(res.data))
              this.setuser(this.user)

              // 动态修改页签标题与图标
              let relIcon = document.head.querySelector("link[rel='icon']");
              relIcon.href = res.data.logo;
              document.title = res.data.title;
            }else{
              this.$message.error(res.msg)
            }
          }
        ).catch(err=>{
          this.$message.error(err.msg)
        })
      },
      // 退出登录
      quit(){
        this.$confirm("真的要注销登录吗 ?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          // 获取当前URL
          const url = new URL(window.location.href)
          // 删除token参数
          url.searchParams.delete('token')
          // 重新加载页面，使用新的URL
          window.location.href = (url.origin+'/web').toString()
          localStorage.removeItem('user')
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
      },
      xiaoxi(){
        this.$emit('getrusult', true)
      }
    }
  });
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="less">
    .top{
      background: #fff;
      box-shadow: 0 0 5px rgb(230, 230, 230);
      color: #101011;
      font-size: 20px;
      height: 72px;
      padding: 0 24px;
      z-index: 500;
      position: relative;
       .name{
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-left: 40px;
          font-size: 14px;
          color: #101011;
          font-weight: 600;
        }
        .color-icon{
          font-size: 22px;
          font-weight: 600;
          margin-right: 10px;
        }
      .left{
        display: flex;
        align-items: center;
        height: 72px;
        img{
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }
       
      }
      .rig{
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .gongsi{
      color: #101011;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      margin: 0 6px 0 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 110px;
    }
  
    //  hover效果
    .khd{
      position: relative;
    }
    .khd:hover .khd-hover{
      display: block;
    }
    .khd-hover{
      display: none;
      width: 277px;
      padding: 40px 0;
      position: absolute;
      left: 0;
      top:50px;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 0 10px #f5f5f5;
      text-align: center;
      img{
        width:120px;
        height: 120px;
      }
      .title{
        font-size: 24px;
        font-weight: bold;
      }
      .mask{
        margin: 10px 0;
        font-size: 12px;
        color: #95989D;
      }
      .edition{
        font-size: 14px;
        color: #95989D;
      }
    }
    
    .flex{
      height: 72px;
      display: flex;
      align-items: center;
      position: relative;
      img{
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
    .flex:hover .account{
      display: block;
    }
    .account{
      display: none;
      width: 300px;
      position: absolute;
      right: 0;
      top:60px;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 0 10px #f5f5f5;
      .account-top{
        cursor: auto;
        padding: 30px 0;
        display: flex;
        align-items: center;
        img{
           width:30px;
          height: 30px;
          border-radius: 50%;
          margin: 0 20px;
        }
        .gs-name{
          span{
            background: #F2F7FF;
            color: #3879FC;
            padding: 2px 5px;
            border-radius: 5px;
            font-size: 12px;
          }
        }
        .gs-id{
          margin-top: 10px;
          font-size: 12px;
          color: #888;
        }
      }
      .account-box{
        padding: 20px;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        .item{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .not-allowed{
          cursor: not-allowed;
          color: #888;
        }
        .space{
          height: 20px;
        }
      }
      .quit{
        padding: 15px 0;
        text-align: center;
        color: #F4624E;
        font-weight: 600;
      }
    }
  </style>
  